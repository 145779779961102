/*
    Settings: GLOBAL

    These are the base settings used globally across the site.
*/

// Fonts
$font-primary: 'Open Sans', sans-serif;
$font-secondary: 'Open Sans', sans-serif;
$font-headings: $font-secondary;

// Sizing and Spacing
$base-font-size: 1.6rem;
$base-line-height: 1.5;

$base-radius: 3px;
$base-gutter: 1rem;
$content-gutter: 1rem;
$button-margin: 1rem 0 1rem 0.8rem; // the last value should be 0.2rem less than the first to account for the HTML spaces between buttons

$base-spacing-unit: 1rem;

// Widths
$width-sm-mobile: 320px;
$width-md-mobile: 375px;
$width-lg-mobile: 425px;
$width-tablet: 768px;
$width-desktop: 1024px;
$width-med-desktop: 1280px;
$width-lg-desktop: 1440px;

$max-width-window: 1366px;
