﻿.c-popout-message-panel {
    $root: &;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    z-index: 9989;

    &__view {
        position: relative;
        width: 100%;
        z-index: 9987;
        overflow: auto;

        &--hidden {
            max-height: 0;
            overflow: hidden;

            #{$root}__view-close {
                display: none;
            }
        }
    }

    &__view-close {
        position: fixed;
        top: 10px;
        right: 15px;
        text-align: left;
        width: 60px;
        height: 25px;
        z-index: 9988;
        background: none;
        padding: 0;
        border: none;
        cursor: pointer;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 9px;
            right: 0;
            background-color: $color-black;
            width: 20px;
            height: 6px;
            border-radius: 2px;
        }

        &:before {
            transform: rotate(-45deg);
        }

        &:after {
            transform: rotate(45deg);
        }
    }

    &__content {
        position: relative;
        background-color: $color-white;
        padding: 10px;
        border-bottom: 1px solid #aaaaaa;
        overflow: auto;
    }

    &__ctrl {
        position: relative;
        text-align: center;
        width: 100%;
        z-index: 9986;
    }

    &__ctrl-button {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        /*background-color: $color-white;*/
        background: linear-gradient(to bottom, rgba(219,219,219,1) 0%,rgba(255,255,255,1) 100%);
        width: 120px;
        height: 7px;
        text-indent: -9999px;
        border-radius: 0 0 8px 8px;
        overflow: hidden;
        box-shadow: 0 0 3px rgba($color-black, 0.8);
        transition: all 0.2s ease-in-out;
        opacity: 0.5;

        &:before {
            content: '';
            position: absolute;
            top: 2px;
            left: 50%;
            margin-left: -21px;
            width: 42px;
            height: 4px;
            background-color: #eeeeee;
            transition: all 0.2s ease-in-out;
            border-radius: 5px;
            box-shadow: 0 0 3px rgba($color-black, 0.4);
            opacity: 0;
        }

        &:after {
            content: '';
            position: absolute;
            top: 4px;
            left: 50%;
            margin-left: -10px;
            width: 20px;
            height: 4px;
            background-color: #eeeeee;
            transition: all 0.2s ease-in-out;
            box-shadow: 0 0 3px rgba($color-black, 0.4);
            border-radius: 5px;
            opacity: 0;
        }

        &:hover {
            height: 30px;
            opacity: 1;

            &:before {
                top: 10px;
                opacity: 1;
            }

            &:after {
                top: 18px;
                opacity: 1;
            }
        }
    }
}
